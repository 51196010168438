@import "../../assets/scss/variables";



.AgeGroup {
  overflow: hidden;
  height: 18px;
  width: max-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $color-text-link;
}

.active {
  text-align: center;
  background-color: $color-secondary-s1;
  color: $color-white;
  align-items: center;
  height: 100%;

  display: flex;
  justify-content: center;
  min-width: 22px;


  span {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 9px;
    font-family: $sans-serif-regular;
  }
}

.inactive {
  background-color: $color-grey-4;
  color: $color-grey-3;
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 22px;


  span {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 9px;
    font-family: $sans-serif-regular;
  }

}
