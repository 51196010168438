@import "src/assets/scss/variables";

.breadcrumb {
  padding-left: 40px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media (max-width: $media-tablet-width) {
    padding-left: 20px;
  }
}

.handout {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  iframe {
    min-height: 80vh;
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
  }
}

.button {
  width: max-content;
  margin: auto;
  padding-bottom: 10px;
  @media (max-width: $media-mobile-width) {
    margin: auto 20px;
    width: unset;
    button {
      min-width: fit-content;
      width: 100%;
    }
  }
}
