@import "src/assets/scss/variables";

.JobmatchPage {
  display: flex;
  flex-direction: column;

  h4, h3 {
    text-align: center;
    margin-top: 0;
  }

  h4 {
    margin-bottom: 0;
  }

  .breadcrumb {
    padding-left: 40px;
    padding-bottom: 20px;
    margin-top: 20px;

    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
  }

  .navigation {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    text-decoration: none !important;

    .navlink {
      text-decoration: none;
    }

    .stepHighlighted {
      font-weight: bold;
    }
  }

  .stepHidden {
    visibility: hidden;
  }

  .jobmatchFrame {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-primary-p1;
    padding: 35px;
    @media (max-width: $media-tablet-width) {
      padding: 35px 20px;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;

    button {
      @media (max-width: $media-mobile-width) {
        min-width: max-content;
        width: 100%;
        margin: 0 30px;
      }
    }
  }

  .stepContent {
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 75px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .internalCourse {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      background: $color-primary-p1;
      min-width: 278px;
      border: none;
      overflow: hidden;
    }
  }

  .competenceLine {
    display: flex;
    align-items: center;
    line-height: 70px;
    gap: 10px;

    .star {
      color: gold;
      cursor: pointer;
      font-size: 61px;
    }

    .starMarked {
      color: goldenrod;
    }

    .thumbsUp {
      color: $color-secondary-s3;
      cursor: pointer;
      font-size: 50px;
    }

    .thumbsUpMarked {
      color: $color-secondary-s1;
    }

    .thumbsDown {
      color: $color-secondary-s2;
      cursor: pointer;

      font-size: 50px;
    }

    .thumbsDownMarked {
      color: $color-error-dark;
    }

  }

  .videoWithText {
    display: flex;
    flex-direction: column;
    min-width: 278px;
    width: 100%;
    gap: 20px;
    align-items: center;
  }

  .foldText {
    max-width: 80%;
    margin-top: 20px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
  }

  .person {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 340px;
    text-align: center;

    h4 {
      color: $color-secondary-s3;
    }

    img {
      border-radius: 10%;
      overflow: hidden;
      margin: 20px;
    }

    .avatar {
      color: $color-secondary-s3;
      font-size: 300px;
    }
  }

  .considerLinebreaks {
    white-space: pre-wrap;
  }

}
