@import "src/assets/scss/variables";

.FormSentPage {
  margin-top: 30px;
  max-width: 1366px;
  height: max-content;
  background-color: $color-primary-p1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sentMessage {
    margin: 30px auto 30px auto;
    width: 50%;
    height: min-content;
    background-color: $color-white;
    @media (max-width: 500px) {
      width: 80%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;

      h2 {
        margin: 0 0 10px 0;
      }
    }

    .thanksText {
      text-align: center;
      white-space: pre-line;
    }
  }

  .backButton {
    margin: 0 auto 30px auto;
    width: max-content;
    padding-bottom: 30px;
    @media (max-width: $media-mobile-width) {
      margin: 30px 20px;
      width: unset;
      button {
        min-width: fit-content;
        width: 100%;
      }
    }
  }
}
