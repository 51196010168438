@import "../../assets/scss/variables";


.miaButton {
  border: 8px solid $color-secondary-s1;
  height: 90px;
  width: 90px;
  border-radius: 90px;
  overflow: hidden;
  cursor:pointer;
  background-color: white;

  .miaRight {
    transform: scale(-1, 1) translate(-30px);
    margin-top: -50px;
    margin-left: -25px;
    height: 400px;
  }

  .miaLeft {
    margin-top: -50px;
    margin-left: -25px;
    height: 400px;
  }

  &:hover {
    border: 8px solid $color-speech-bubble;
  }
}
