@import "src/assets/scss/variables";

.dashboardBannerCarousel {

  .carouselContent {

    .activeSlide {
      display: block;
      opacity: 1;
      transition-duration: 800ms;
    }

    .inactiveSlide {
      opacity: 0;
      transition-duration: 800ms;
    }
  }

    .indicators {
      position: absolute;
      display: flex;
      flex-direction: row;
      top: 90%;
      left: 20px;
      justify-content: initial;
      z-index: 4;

      @media (max-width: $media-tablet-width) {
        top: 77%;
        right: 25px;
        left: unset;
      }

      @media (max-width: 556px) {
        top: 85%;
        right: 30px;
      }

      @media (max-width: $media-mobile-width) {
        top: 88%;
        right: 10px;
      }

      .indicator {
        cursor: pointer;
        height: 12px;
        width: 12px;
        border-radius: 3px;
        margin: 0 3px;
        border-width: 2px;
        border-style: solid;

        @media (max-width: $media-tablet-width) {
          height: 40px;
          width: 40px;
          margin: 0 7px;
        }

        @media (max-width: 556px) {
          height: 15px;
          width: 15px;
          margin: 0 3px;
        }
      }

      .activeIndicator {
        background-color: $color-secondary-s3;
        border-color: $color-secondary-s3;
      }

      .inactiveIndicator {
        border-color: $color-white;
        background-color: transparent;

        @media (max-width: $media-tablet-width) {
          border-color: $color-secondary-s3;
        }

        @media (max-width: 556px) {
          border-color: rgba(255, 255, 255, 1);
        }
      }
    }
}
