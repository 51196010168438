
.pants_0 {
  --color-clothing-gradient-1: #5c9677; //pants
  --color-clothing-gradient-2: #678675; //knees
  --color-belt-gradient-0: #86552f; //belt (main)
  --color-belt-gradient-1: #bca18e; //belt (buckle)
}

.pants_1 {
  --color-clothing-gradient-1: #195f73; //pants
  --color-clothing-gradient-2: #5A6377; //knees
  --color-belt-gradient-0: #86552f; //belt (main)
  --color-belt-gradient-1: #846c06; //belt (buckle)
}

.pants_2 {
  --color-clothing-gradient-1: #404242; //pants
  --color-clothing-gradient-2: #5c5b5a; //knees
  --color-belt-gradient-0: #5b5a59; //belt (main)
  --color-belt-gradient-1: #bca18e; //belt (buckle)
}
