@import "src/assets/scss/variables";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: $sans-serif-regular;
  font-weight: $font-weight-bold;
  font-style: normal;
  font-size: 42px;
  letter-spacing: 0.16px;
  color: $color-secondary-s1;
  text-align: left;
  line-height: 52px;
}

h2 {
  font-family: $sans-serif-regular;
  font-weight: $font-weight-bold;
  font-style: normal;
  font-size: 34px;
  color: $color-secondary-s1;
  text-align: left;
  line-height: 40px;
}

h3 {
  font-family: $sans-serif-regular;
  font-weight: $font-weight-medium;
  font-style: normal;
  font-size: 30px;
  color: $color-secondary-s1;
  text-align: left;
  line-height: 34px;
}

h4 {
  font-family: $sans-serif-regular;
  font-weight: $font-weight-medium;
  font-style: normal;
  font-size: 18px;
  color: $color-secondary-s1;
  text-align: left;
  line-height: 26px;
}
