@import "src/assets/scss/variables";

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;

  button {
    @media (max-width: 1240px) {
      width: 100%;
      min-width: fit-content!important;
    }

    @media (max-width: $media-tablet-width) {
      width: 42%;
      min-height: 48px!important;
    }

    @media (max-width: $media-mobile-width) {
      height: 48px;
      width: 100%;
      min-width: fit-content;
    }
  }

  .header {
    color: $color-secondary-s1;
    font-family: $sans-serif-regular;
    font-weight: $font-weight-bold;
    font-size: 42px;
    text-align: start;

    @media (max-width: $media-tablet-width) {
      font-size: 34px;
    }

    @media (max-width: 500px) {
      font-size: 24px;
    }
  }

  .footer {
    max-width: 510px;
    padding-right: 30px;
    padding-top: 5px;

    .description {
      color: $color-black;
      font-family: $sans-serif-regular;
      font-size: 16px;
      font-weight: $font-weight-regular;
      line-height: 24px;
      padding-bottom: 40px;

      p {
        @media (max-width: $media-tablet-width) {
          margin: 20px 0 10px 0;
        }

        @media (max-width: $media-mobile-width) {
          margin: 10px 0 0 0;
        }
      }

      @media (max-width: $media-tablet-width) {
        padding-bottom: 20px;
        padding-top: 0;
      }

      @media (max-width: $media-mobile-width) {
        font-size: 14px;
        padding-bottom: 15px;
      }
    }

    @media (max-width: $media-tablet-width) {
      max-width: 100%;
    }

    @media (max-width: $media-mobile-width) {
      padding-right: 0;
      padding-top: 0;
    }
  }

  @media (max-width: $media-tablet-width) {
    max-height: 216px;
    justify-content: flex-start;
  }

  @media (max-width: $media-mobile-width) {
    padding-bottom: 35px;
  }
}
