@import "../../assets/scss/variables";
@import "../../components/Mia/Mia.module";

.CodingChallenge {
  margin-top: 35px;
  padding: 0 10px;

  .breadcrumb {
    padding-left: 20px;
    @media(max-width: $media-tablet-width) {
      padding-left: 10px;
    }
    @media(max-width: $media-mobile-width) {
      padding-left: 0;
    }
  }

  .openEditor {
    padding: 30px 30px 30px 30px;
    background-color: rgba(59, 180, 180, 0.1);
    margin-top: 30px;
    @media (max-width: $media-mobile-width) {
      padding: 20px;
    }

    .codingChallengeContent {
      box-sizing: border-box;
      background-color: white;
      padding: 30px;
      @media (max-width: $media-mobile-width) {
        padding: 20px;
      }

      .title {
        font-family: $sans-serif-regular;
        font-weight: $font-weight-bold;
        color: $color-secondary-s1;
        text-align: left;
        line-height: normal;
        font-size: 24px;
        margin-bottom: 23px;
      }

      .description {
        font-family: $sans-serif-regular;
        font-weight: $font-weight-regular;
        color: $color-black;
        text-align: left;
        line-height: 28px;
        font-size: 16px;
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 30px;
        padding-right: 10px;

        button {
          @media (max-width: 500px) {
            min-width: fit-content!important;
            width: 100%;
          }

          div {
            width: unset;
          }
        }

        div {
          @media (max-width: 500px) {
            width: 100%;
            min-width: fit-content;
          }

          label {
            @media (max-width: 500px) {
              max-width: 100%;
              min-width: fit-content;
            }

            div {
              width: unset;
            }
          }
        }
      }

      .guidelineWrapper {
        .guidelineTitle {
          font-family: $sans-serif-regular;
          font-weight: $font-weight-regular;
          color: $color-grey-1;
          text-align: left;
          line-height: 28px;
          font-size: 16px;
          margin-top: 25px;
          margin-bottom: 20px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

    }
  }

  .uploadImageWrapper {
    margin: 20px 0;
    padding: 20px;
    background-color: $color-background;

    .uploadImageTitle {
      @media (max-width: $media-tablet-width) {
        margin: 0 0 10px; // top right-left bottom
        font-size: 28px;
      }
      @media (max-width: $media-mobile-width) {
        font-size: 24px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .codingImagesWrapper {
    margin: 20px 0;
    padding: 20px;
    background-color: $color-background;

  .images {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    @media (max-width: $media-mobile-width) {
      gap: 0;
    }

    img {
      width: 315px;
      height: 352px;
    }
  }
  }
}
