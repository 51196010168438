@import "src/assets/scss/variables";

.ContactPage {
  display: flex;
  justify-content: center;
  max-width: 1366px;
  height: max-content;
  background-color: $color-primary-p1;
  overflow: auto;
  padding: 20px 20px 50px;
  margin-top: 10px;
  @media (max-width: $media-mobile-width) {
    margin-top: 0;
    padding: 0 10px 20px; //top left-right bottom
  }
}

.form {
  width: 751px;
  height: max-content;
  background-color: $color-white;
  margin: 30px auto 0 auto;
  border-radius: 3px;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .header {
    padding: 10px 50px 0 50px;
    @media (max-width: $media-tablet-width) {
      font-size: 28px;
    }
    @media (max-width: $media-mobile-width) {
      padding: 0 20px;
      font-size: 24px;
    }
  }

  .introText {
    padding: 0 50px;
    @media (max-width: $media-mobile-width) {
      padding: 0 20px;
      font-size: 14px;
    }
    white-space: pre-line;
  }

  .inputFields {
    width: 86.5%;
    padding: 30px 50px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: $media-mobile-width) {
      padding: 30px 20px;
    }
  }

  .inputField {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .withError {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .winnieThePoo {
    display: none;
  }

  .buttonContainer {
    width: 100%;

    .button {
      width: max-content;
      margin: 0 auto;
      padding-bottom: 30px;
      @media (max-width: $media-mobile-width) {
        margin: 30px 20px;
        width: unset;
        button {
          min-width: fit-content;
          width: 100%;
        }
      }
    }
  }
}
