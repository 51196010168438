// the default custom-properties
.default_customization {
    //clothing
    --color-backpack-gradient-0: #7a394f;
    --color-backpack-gradient-1: #6c6f56;
    --color-clothing-gradient-0: #5fb5c1; //jacket
    --color-clothing-gradient-1: #5c9677; //pants
    --color-clothing-gradient-2: #678675; //knees
    --color-shirt-gradient-0: #b3e6bc; //shirt (main)
    --color-shirt-gradient-1: #8eaa90; //shirt (fold)
    --color-belt-gradient-0: #86552f; //belt (main)
    --color-belt-gradient-1: #bca18e; //belt (buckle)

    //ethnicity
    --skin-color-primary: #EABF83; //feet, arms, neck lighter
    --skin-color-secondary: #F8D07B; //face-lighter, ears
    --skin-color-grey-1: #f9b877; //face-darker
    --skin-color-details: #E4B274; //face details, incl. cheeks, nose contour & nose tip
    --skin-color-shadows: #CD9F5D;// neck darker
    --skin-color-nose-base: #f7c77a; //nose-face

    --color-skin-gradient-0: var(--skin-color-secondary); //face lighter (right) and neck bottom
    --color-skin-gradient-1: var(--skin-color-primary); //neck-middle
    --color-skin-gradient-2: var(--skin-color-shadows); //neck-top
    --color-skin-gradient-3: var(--skin-color-grey-1); //face darker (left)
    --color-skin-gradient-4: var(--skin-color-details); //nose-tip
    --color-skin-gradient-5: var(--skin-color-nose-base); //nose-face
    --color-skin-gradient-6: var(--skin-color-primary); //feet
    --color-skin-gradient-7: var(--skin-color-primary); //arms
    --color-skin-gradient-8: var(--skin-color-secondary); //ears
    --color-cheeks-gradient-0: var(--skin-color-details);
    --color-skindetails-gradient-0: var(--skin-color-details);
    --color-skindetails-gradient-1: var(--skin-color-nose-base);
    --color-lips-gradient-0: #ec6b60;

    //hair
    --color-hair-gradient-0: #aa733f;
    --color-hair-gradient-1: #784616;
    --color-hair-gradient-2: #aa733f;
    --color-hair-gradient-3: #875731; //details
    --color-eyebrows-gradient-0: #7d521c;

    //eyes
    --color-eyes-gradient-0: #184e43;
    --color-eyes-gradient-1: #789475;
}
