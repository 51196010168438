
.eyes_0 {
  --color-eyes-gradient-0: #184e43;
  --color-eyes-gradient-1: #789475;
}

.eyes_1 {
  --color-eyes-gradient-0: #112438;
  --color-eyes-gradient-1: #547089;
}

.eyes_2 {
  --color-eyes-gradient-0: #20130B;
  --color-eyes-gradient-1: #3D2818;
}
