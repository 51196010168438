
.hair_0 {
  --color-hair-gradient-0: #aa733f;
  --color-hair-gradient-1: #784616;
  --color-hair-gradient-2: #aa733f;
  --color-hair-gradient-3: #875731; //details
  --color-eyebrows-gradient-0: #7d521c; //eyelids & eyebrows
}

.hair_1 {
  --color-hair-gradient-0: #db3a1e;
  --color-hair-gradient-1: #722412;
  --color-hair-gradient-2: #b73320;
  --color-hair-gradient-3: #722412; //details
  --color-eyebrows-gradient-0: #4A4337; //eyelids & eyebrows
}

.hair_2 {
  --color-hair-gradient-0: #1f2b50;
  --color-hair-gradient-1: #23110a;
  --color-hair-gradient-2: #1f2b50;
  --color-hair-gradient-3: #23110a; //details
  --color-eyebrows-gradient-0: #4A4337; //eyelids & eyebrows
}
