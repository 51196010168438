@import "../../assets/scss/variables";

.miaFixed {
  z-index: 10;
  position: fixed;
  bottom: 21px;
  right: 21px;

  .miaHandler {
    position: fixed;
    bottom: -260px;
    right: 21px;
    @media (max-width: $media-mobile-mia-width) {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: unset;
      padding-right: 0;
    }
  }
}

.mia {
  pointer-events: none;
}

.miaCustomization {
  @media (max-width: $media-mobile-mia-width) {
    display: none;
  }
}

.miaLeft {
  height: 500px;
  width: 160px;
  position: relative;
  top: -80px;
  left: 200px;
  z-index: 15;
  transform: scale(1, 1);
}

.miaRight {
  height: 500px;
  width: 160px;
  position: relative;
  top: -80px;
  left: 40px;
  z-index: 15;
  transform: scale(-1, 1) translate(30px);
}

.chatBubble {
  pointer-events: all;
  width: 340px;
  min-height: 100px;
  position: relative;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  background: $color-white;
  border-radius: 10px;
  padding: 20px 23px 20px 23px;
  text-align: center;
  border: solid 2px $color-speech-bubble;
  @media (max-width: $media-mobile-mia-width) {
    width: unset;
    height: 100vh;
    border: none;
    border-radius: 0;
  }

  .icons {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: flex-end;
    gap: 8px;
    @media (max-width: $media-mobile-mia-width) {
      padding-top: 10px;
      padding-bottom: 20px;
      justify-content: space-between;
    }

    .logo {
      display: none;
      @media (max-width: $media-mobile-mia-width) {
        display: flex;
        object-fit: contain;
        max-width: 40px;
        max-height: 40px;
      }

      img {
        @media (max-width: $media-mobile-mia-width) {
          max-width: 100px;
        }
      }
    }
    .iconsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 15px;
      .close {
        display: flex;
        padding: 0;
        background-color: transparent;
        cursor: pointer;

        span {
          margin: -12px -9px;
        }
      }
    }
  }
}

.pointer {
  @media (max-width: $media-mobile-mia-width) {
    display: none;
  }
}

// facing left
.pointer_0 {
  position: absolute;
  right: 150px;
  bottom: 0;

  .border {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 9px solid transparent;
    border-top: 30px solid $color-speech-bubble;
    transform: skew(30deg);
  }

  .fill {
    position: absolute;
    top: -6px;
    left: -3px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 9px solid transparent;
    border-top: 30px solid white;
    transform: skew(30deg);
  }
}

// facing right
.pointer_1 {
  position: absolute;
  left: 100px;
  bottom: 0;

  .border {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 9px solid transparent;
    border-top: 30px solid $color-speech-bubble;
    transform: skew(-30deg);
  }

  .fill {
    position: absolute;
    top: -6px;
    left: 3px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 9px solid transparent;
    border-top: 30px solid white;
    transform: skew(-30deg);
  }
}


.border {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 9px solid transparent;
  border-top: 30px solid $color-speech-bubble;
  transform: skew(30deg);
}

.fill {
  position: absolute;
  top: -6px;
  left: -3px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 9px solid transparent;
  border-top: 30px solid white;
  transform: skew(30deg);
}
