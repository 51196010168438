@import 'src/assets/scss/variables';

.LegalNoticePage {
  padding: 0 50px 50px 50px;
  @media (max-width: $media-mobile-width) {
    padding: 0 20px 50px 20px;
  }

  .breadcrumb {
    padding-top: 20px;
  }

  h1 {
    @media (max-width: $media-tablet-width) {
      font-size: $font-size-h3;
    }
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-h4;
    }
  }

  p {
    padding-left: 50px;
    font-family: $sans-serif-regular;
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: $font-size-body-1;
    color: $color-text-body;
    line-height: 28px;
    @media (max-width: $media-tablet-width) {
      padding-left: 10px;
    }
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-body-2;
      line-height: 26px;
    }
  }

  .bold {
    font-weight: $font-weight-bold;
    margin-top: 3em;
  }

  .list {
    ol {
      list-style-type: none;
    }
    li {
      counter-increment: count-me;
      font-family: $sans-serif-regular;
      font-weight: $font-weight-regular;
      font-style: normal;
      font-size: 16px;
      color: $color-text-body;
      line-height: 28px;
      @media (max-width: $media-mobile-width) {
        font-size: $font-size-body-2;
        line-height: 26px;
      }
    }
    li:before {
      content: counter(count-me) ". ";
      display: block;
      position: relative;
      max-width: 0;
      max-height: 0;
      left: -1.5em;
      color: $color-text-body;
      font-weight: $font-weight-bold;
    }
  }

  a {
    text-decoration: none;
    color: $color-secondary-s1;
  }
}
