@import "src/assets/scss/variables";

.consent {
  span {
    @media (max-width: $media-mobile-width) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.consentText {
  color: $color-text-body;
  font-size: $font-size-tooltip;
  font-weight: $font-weight-regular;
  font-family: $sans-serif-regular;
  font-style: unset;
  line-height: 16px;
  margin-bottom: 8px;
  text-align: left;
}
