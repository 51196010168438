@import "../../assets/scss/variables";

.ContentDescription {
  h1 {
    font-family: $sans-serif-regular;
    font-weight: $font-weight-bold;
    font-size: $font-size-h2;
    color: $color-text-heading;
    letter-spacing: normal;
    line-height: 34px;
    text-align: left;
    margin-bottom: 20px;
    @media (max-width: $media-tablet-width) {
      font-size: 28px;
    }
    @media (max-width: $media-mobile-width) {
      font-size: 20px;
    }
  }

  .description {
    font-family: $sans-serif-regular;
    color: $color-text-body;
    font-size: $font-size-body-1;
    line-height: 28px;
    @media (max-width: $media-tablet-width) {
      line-height: 24px;
    }
    @media (max-width: $media-mobile-width) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .competencesHeader {
    font-family: $sans-serif-regular;
    color: $color-text-body;
    font-size: $font-size-body-1;
    font-weight: $font-weight-bold;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .competences {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .competence {
      display: flex;
      flex-direction: row;
      font-family: $sans-serif-regular;
      color: $color-text-body;
      font-size: $font-size-body-1;
      line-height: 28px;
      gap: 4px;

      .icon {
        margin-top: 3px;
      }
    }
  }

}
