@import "../../assets/scss/variables";

$media-user-info-break-point: 450px;

.Profile {
  padding: 20px;
  @media (max-width: $media-mobile-width) {
    padding: 10px 20px;
  }

  .breadcrumb {
    padding: 10px 20px;
    @media (max-width: $media-tablet-width) {
      padding: 10px 0;
    }
  }

  .notificationBanner {
    margin-top: 10px;
    max-width: 375px;
    margin-left: 20px;
    @media (max-width: $media-tablet-width) {
      margin-left: 0;
    }
  }

  .headers {

    h3 {
      @media (max-width: $media-tablet-width) {
        font-size: $font-size-h3;
      }
      @media (max-width: $media-mobile-width) {
        font-size: $font-size-h4;
      }
    }

    .description {
      line-height: 24px;
      color: $color-text-body;
      @media (max-width: $media-mobile-width) {
        line-height: 22px;
        text-align: justify;
      }
    }

    .pageContent {
      .selectableTile {
        margin-right: auto;
      }
    }
  }

  .userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-right: 20px;

    @media (max-width: $media-user-info-break-point) {
      flex-wrap: wrap;
      justify-content: center;
      padding-right: 0;
    }

    .userAvatar {
      width: 152px;
      height: 152px;
      position: relative;
      padding: 42px 20px;
      flex-shrink: 1;
      text-align: center;
      color: $color-secondary-s3;
      @media (max-width: $media-user-info-break-point) {
        padding: 20px 10px 20px 0;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        border: 5px solid $color-secondary-s3;
      }

      .cameraIcon {
        display: flex;
        position: absolute;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        color: $color-secondary-s1;
        background-color: $color-white;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: 15px;
        bottom: 30px;
        @media (max-width: $media-user-info-break-point) {
          bottom: 7px;
        }

        .inputFieldWrapper {
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;

          .inputField {
            display: none;
          }
        }
      }
    }

    .textInput {
      @media (max-width: $media-user-info-break-point) {
       width: 70%;
      }
      @media (max-width: $media-mobile-width) {
        width: 100%;
      }
    }
  }
  .saveButton {
    padding: 40px 0 30px 0;
    display: flex;
    justify-content: center;

    button {
      @media (max-width: $media-mobile-width) {
        width: 100%;
        min-width: fit-content!important;
      }
    }
  }
}
