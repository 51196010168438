@import "../../../assets/scss/variables";

.chatContainer {
  height: 353px;
  padding-top: 5px;

  @media (max-width: $media-mobile-mia-width) {
    height: 80%;
  }
}
