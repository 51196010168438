@import "src/assets/scss/variables";

$media-before-tablet-breakpoint: 1224px;
$media-small-tablet-width: 688px;

.ContentInformationBlock {
  width: 100%;
  min-width: 316px;
  min-height: 100px;
  height: 100%;
  background-color: $color-info-background;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    padding: 30px;
    font-family: $sans-serif-regular;
    font-size: $font-size-body-2;
    color: $color-text-body;
    line-height: 22px;
  }

  @media (max-width: $media-before-tablet-breakpoint) {
    max-width: 350px;
    min-width: unset;
  }

  @media (max-width: $media-tablet-width) {
    max-height: 228px;
  }

  @media (max-width: $media-small-tablet-width) {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: fit-content;
    justify-content: start;
  }
}

.table {
  display: table;
  width: 100%;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
  padding-bottom: 4px;
  vertical-align: middle;
}

.cellLeft {
  @extend .cell;
  padding-right: 10px;
}

.authorCell {
  @extend .cell;
  vertical-align: top;
}

.author {
  display: table-row;
  .icon {
    padding-top: 3px;
    display: table-cell;
    color: $color-secondary-s1;
  }
  .name {
    display: table-cell;
    padding-left: 3px;
    vertical-align: top;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding-top: 17px;
  .tag {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
