@import "src/assets/scss/variables";

$media-font-size: 800px;
$media-switch-to-column-width: 600px;

.pastEvent {
  background-color: $event-background-color;

  .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: $media-tablet-width) {
      flex-direction: column;
      position: relative;
    }
    padding: 23px 20px;

    .imageFrame {
      display: flex;
      flex-direction: column;
      flex: 0.9;
      overflow: hidden;
      justify-content: center;
      max-width: 487px;
      max-height: 315px;
      @media (max-width: $media-mobile-width) {
        max-width: fit-content;
        max-height: fit-content;
      }

      img {
        object-fit: cover;
        @media (max-width: $media-tablet-width) {
          max-width: 144px;
          max-height: 101px;
        }
        @media (max-width: $media-switch-to-column-width) {
          max-width: 100%;
          max-height: fit-content;
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 10px;
      @media (max-width: $media-tablet-width) {
        padding-top: 0;
      }

      .titleLabels {
        @media (max-width: $media-tablet-width) {
          position: absolute;
          top: 25px;
          left: 190px;
          padding-right: 10px;
        }
        @media (max-width: $media-switch-to-column-width) {
          position: static;
        }

        .title {
          font-family: $sans-serif-regular;
          font-weight: $font-weight-medium;
          font-size: $font-size-h3;
          color: $color-secondary-s1;
          text-align: left;
          line-height: normal;
          padding-bottom: 10px;
          @media (max-width: $media-tablet-width) {
            font-size: $font-size-h4;
            font-weight: $font-weight-bold;
          }
          @media (max-width: $media-font-size) {
            font-size: $font-size-body-lead;
          }
          @media (max-width: $media-mobile-width) {
            font-weight: $font-weight-medium;
            font-size: $font-size-body-1;
          }
        }

        .labels {
          :nth-child(2) {
            color: $color-text-body;
            font-size: $font-size-body-2;
          }
          line-height: 14px;
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 7px;
          padding-bottom: 18px;
          @media (max-width: $media-tablet-width) {
            flex-direction: row;
          }
          @media (max-width: $media-mobile-width) {
            flex-direction: column;
          }
        }
      }

      .description {
        color: $color-text-body;
        font-family: $sans-serif-regular;
        font-weight: $font-weight-regular;
        font-size: $font-size-body-1;
        line-height: 24px;
        text-align: left;
        a {
          text-decoration: none;
        }
        @media (max-width: $media-tablet-width) {
          line-height: 21px;
          text-align: justify;
        }
        @media (max-width: $media-mobile-width) {
          font-size: $font-size-body-2;
        }
      }
    }
  }
}
