@import "src/assets/scss/variables";

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 356px;
  align-items: flex-start;
  text-align: justify;

  button {

    @media (max-width: $media-tablet-width) {
      min-width: 35%!important;
      min-height: 48px!important;
    }

    @media (max-width: $media-mobile-width) {
      height: 48px;
      width: 100%;
      min-width: fit-content;
    }
  }

  .header {
    color: $color-secondary-s1;
    font-family: $sans-serif-regular;
    font-weight: $font-weight-bold;
    font-size: 42px;
    text-align: start;

    @media (max-width: $media-tablet-width) {
      font-size: 34px;
    }

    @media (max-width: 500px) {
      font-size: 24px;
    }
  }

  .description {
    color: $color-black;
    font-family: $sans-serif-regular;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 24px;
    padding: 20px 0 25px 0;
    max-width: 356px;

    @media (max-width: $media-tablet-width) {
      max-width: 100%;
      padding: 5px 0 25px 0;
    }

    @media (max-width: 546px) {
      padding: 5px 0;
    }

    @media (max-width: $media-mobile-width) {
      font-size: 14px;
      line-height: 21px;
      padding-top: 0;
    }
  }

  @media (max-width: 1240px) {
    max-width: 100%;
  }

  @media (max-width: $media-tablet-width) {
    max-height: 216px;
    justify-content: flex-start;
    gap: 15px;
  }

  @media (max-width: $media-mobile-width) {
    padding: 10px;
  }
}
