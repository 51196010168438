$color-primary-p1: #f1f8f9;
$color-primary-p2: #f3f9fa;
$color-primary-p3: #bde3e5;

$color-secondary-s1: #137171;
$color-secondary-s2: #c8c896;
$color-secondary-s3: #3bb4b4;

$color-white: #FFFFFF;
$color-black: #303030;

$color-grey-1: #28323c;
$color-grey-2: #656d75;
$color-grey-3: #b7bcc1;
$color-grey-4: #e0e4e7;

$color-error-dark: #cd4c54;
$color-error-light: #ffe9e3;

//text colors
$color-text-heading:  $color-secondary-s1;
$color-text-subheading: $color-text-heading;
$color-text-body: $color-black;
$color-text-link: $color-secondary-s1;

// background colors
$color-background: $color-primary-p1;
$color-header-background: $color-primary-p1;
$color-speech-bubble: $color-primary-p3;
$color-info-background: $color-primary-p2;
$event-background-color: $color-primary-p1;

//footer colors
$color-footer-links: $color-grey-2;
$color-footer-line: $color-secondary-s2;
