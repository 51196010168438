@import "../../assets/scss/variables";

.debouncedTextInput {
  display: flex;
  min-height: 44px;
  border-radius: 2px;
  background-color: $color-white;
  font-family: $sans-serif-regular;
  color: $color-grey-2;
  text-align: left;
  align-items: center;
  padding-right: 10px;
  padding-left: 14px;

  &:hover {
    border-left: 2px solid $color-secondary-s3;
    padding-left: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus-within {
    border-left: 4px solid $color-secondary-s3;
    padding-left: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .icon {
    padding-right: 10px;
    cursor: pointer;
    color: $color-secondary-s1;
  }

  .input {
    font-family: $sans-serif-regular;
    color: $color-black;
    background: none;
    border-style: none;
    width: 100%;
    font-size: 14px;
    line-height: 18px;

    &:focus, &:active, &:visited {
      outline: none;
    }

    &::placeholder {
      color: $color-grey-2;
    }
  }
}
