@import "src/assets/scss/variables";

.LearningOffers {
  display: flex;
  flex-direction: column;

  .breadcrumb {
    padding: 20px 0 25px 40px;
    @media (max-width: $media-mobile-width) {
      padding-left: 20px;
    }
  }

  .empty {
    padding: 40px;
    font-family: $sans-serif-regular;
    font-size: $font-size-body-1;
    color: $color-black;
  }

  .courses {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: $color-primary-p1;
    min-height: 567px;

    .carousels {
      padding: 30px 20px 60px 20px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width: $media-mobile-width) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .lessonWrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
    @media (max-width: $media-mobile-width) {
      gap: 0;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 551px;

  .modalHeaders {

    p{
      font-family: $sans-serif-regular;
      text-align: left;
      line-height: 24px;
      font-size: 18px;
      @media (max-width: $media-mobile-width) {
        line-height: 21px;
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
  }

  .modalButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    @media (max-width: $media-mobile-width) {
      justify-content: space-between;
      button {
        height: 48px;
      }
    }
    @media (max-width: 350px) {
      flex-wrap: wrap;
      button {
       min-width: max-content;
        width: 100%;
      }
    }
  }
}
