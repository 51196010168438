@import "src/assets/scss/variables";

.LegalPrivacyNoticeBar {
  display: flex;
  width: 100%;
  min-height: 60px;
  background-color: $color-header-background;

  @media (max-width: 632px) {
    flex-direction: column-reverse;
    gap: 30px;
    padding: 30px 0;
  }

  .copyright {
    flex: 1 1 0;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 632px) {
      justify-content: center;
    }

    span {
      padding-left: 5px;
      font-family: $sans-serif-regular;
      color: $color-text-subheading;
      font-size: $font-size-body-2;

      @media (max-width: 632px) {
        font-size: $font-size-body-1;
      }
    }
  }

  .privacyNotice {
    flex: 1 1 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: $media-tablet-width) {
      justify-content: flex-end;
      padding-right: 10px;
    }

    @media (max-width: 632px) {
      justify-content: center;
    }

    .privacyNoticeLink {
      height: 35px;
      text-align: center;
      cursor: pointer;
      color: $color-text-subheading;
      font-size: $font-size-tooltip;
      font-family: $sans-serif-regular;

      @media (max-width: 632px) {
        font-size: $font-size-body-1;
      }

      button {
        height: 46px;
      }
    }
  }

  .rightSpace {
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    @media (max-width: $media-tablet-width) {
      display: none;
    }
  }
}
