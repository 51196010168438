@import "../../assets/scss/variables";

.MiaCustomizationPage {

  display: flex;
  flex-direction: column;
  font-family: $sans-serif-regular;
  margin: 30px 40px;
  @media (max-width: $media-mobile-width) {
    margin: 0;
    padding: 30px 20px;
  }

  .header {
    font-weight: $font-weight-bold;
    font-size: $font-size-h1;
    color: $color-secondary-s1;
    margin-bottom: 20px;
    @media (max-width: $media-tablet-width) {
      font-size: $font-size-h3;
    }
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-h4;
    }
  }

  .description {
    font-weight: $font-weight-regular;
    font-size: $font-size-body-1;
    color: $color-grey-1;
    margin-bottom: 40px;
    max-width: 740px;
    line-height: 28px;
    @media (max-width: $media-mobile-width) {
      margin-bottom: 20px;
    }
  }

  .customization {
    display: flex;
    flex-direction: column;
    width: max-content;
    @media (max-width: 965px) {
      gap: 30px;
    }
    @media (max-width: 655px) {
      justify-content: center;
      width: 100%;
    }

    .selection {
      display: flex;
      flex-direction: row;
      @media (max-width: 965px) {
        display: flex;
        flex-direction: column;
        width: auto;
        position: relative;
      }
      @media (max-width: 655px) {
        justify-content: center;
        text-align: center;
      }

      .categories {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        cursor: pointer;
        @media (max-width: 655px) {
          justify-content: center;
          width: 100%;
        }
      }

      .mia {
        justify-content: center;
        max-height: 700px;
        margin-top: -10%;
        margin-bottom: -6%;
        display: flex;
        width: 300px;
        @media (max-width: 965px) {
          position: absolute;
          height: 100%;
          right: -362px;
          bottom: 0;
        }
        @media (max-width: 725px) {
          right: -297px;
        }
        @media (max-width: 655px) {
          margin-top: 0;
          position: unset;
          height: 500px;
          text-align: center;
          width: auto;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      button {
        @media (max-width: $media-mobile-width) {
          width: 100%;
          min-width: fit-content!important;
        }
      }
    }
  }
}
