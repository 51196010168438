@import "../../assets/scss/variables";

.pressEntry {
  background-color: white;
  max-width: 852px;
  display: flex;
  flex-direction: column;
  padding: 17px;
  font-family: $sans-serif-regular;
  border-left: 6px solid $color-background;
  cursor: pointer;

  .title {
    padding-bottom: 15px;

    span {
      color: $color-secondary-s1;
      font-size: 18px;
      letter-spacing: 0.16px;
      line-height: 22px;
      font-weight: $font-weight-regular;
      font-style: normal;
      @media (max-width: $media-mobile-width) {
        font-size: 16px;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 7px;
    @media (max-width: $media-mobile-width) {
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .description {
    padding-top: 11px;
    font-size: $font-size-body-2;
    line-height: 20px;
    font-weight: $font-weight-regular;
    color: $color-black;
    font-style: normal;
  }

  &:hover {
    border-left: 6px solid $color-secondary-s1;
  }
}
