
.skin_0 {
  --skin-color-primary: #EABF83; //feet, arms, neck lighter
  --skin-color-secondary: #F8D07B; //face-lighter, ears
  --skin-color-grey-1: #f9b877; //face-darker
  --skin-color-details: #E4B274; //face details, incl. cheeks, nose contour & nose tip
  --skin-color-shadows: #CD9F5D;// neck darker
  --skin-color-nose-base: #f7c77a; //nose-face
  --color-lips-gradient-0: #ec6b60;
}

.skin_1 {
  --skin-color-primary: #c98349; //feet, arms, neck lighter
  --skin-color-secondary: #e2a050; //face-lighter, ears
  --skin-color-grey-1: #ba782f; //face-darker
  --skin-color-details: #ba782d; //face details, incl. cheeks, nose contour & nose tip
  --skin-color-shadows: #a36629;// neck darker
  --skin-color-nose-base: #D89149; //nose-face
  --color-lips-gradient-0: #D37565;
}

.skin_2 {
  --skin-color-primary: #724729; //middle neck, arms, feet
  --skin-color-secondary: #87521b; //face right side, ear, bottom neck
  --skin-color-grey-1: #87521b; //face left side
  --skin-color-shadows: #492c15;//top neck
  --skin-color-details: #a85d16;
  --skin-color-nose-base: #87521b;
  --color-lips-gradient-0: #a33328;
}
