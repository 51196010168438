@import "src/assets/scss/variables";


.BlogEntry {
  max-width: 415px;
  height: 430px;
  background-color: white;
  border-bottom: 10px solid white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @media (max-width: $media-tablet-width) {
    max-width: 315px;
    height: 420px;
  }

  &:hover {
    border-bottom: 10px solid $color-secondary-s1;
  }

  .imageFrame {
    height: 198px;

    img {
      height: 198px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .bottom {
    display: flex;
    max-height: 236px;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: $media-tablet-width) {
      max-height: 100%;
    }

    .content {
      margin: 15px 26px auto 33px;
      @media (max-width: $media-tablet-width) {
        margin-top: 25px;
      }

      .blog {
        font-family: $sans-serif-regular;
        font-weight: $font-weight-bold;
        color: $color-text-heading;
        font-size: 13px;
        text-transform: uppercase;
      }

      .title {
        font-family: $sans-serif-regular;
        font-weight: $font-weight-bold;
        color: $color-text-heading;
        font-size: 24px;
        margin-top: 6px;
        margin-bottom: 6px;
        @media (max-width: $media-tablet-width) {
          font-size: 16px;
          font-weight: $font-weight-medium;
        }
      }

      .description {
        margin-bottom: 12px;
        margin-top: 12px;

        p {
          line-height: 20px;
          margin: 0;
          padding: 0;
          font-family: $sans-serif-regular;
          font-size: $font-size-body-2;
          color: $color-text-body;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }

      .details {
        display: flex;
        flex-direction: row;
        gap: 12px;

        .detail {
          display: flex;
          flex-direction: row;
          gap: 2px;
          font-family: $sans-serif-regular;
          font-weight: $font-weight-regular;
          font-size: 12px;
          color: $color-text-link;
          line-height: 22px;
        }
      }
    }
  }
}
