@import "src/assets/scss/variables";

$media-small-tablet-width: 800px;
$media-mobile-width: 400px;

.InitiativePage {
  margin: 0 40px 60px 40px;

  .breadcrumb {
    padding-top: 20px;
  }

  h1 {
    margin-bottom: 0;
    @media (max-width: $media-mobile-width) {
      font-size: 24px;
    }
  }

  .content {
    margin-top: 20px;
    display: flex;
    @media (max-width: $media-small-tablet-width) {
      flex-wrap: wrap;
    }
  }

  .left {
    margin-right: 20px;
    @media (max-width: $media-small-tablet-width) {
      margin-right: 0;
    }
  }

  p {
    font-family: $sans-serif-regular;
    font-size: 16px;
    color: $color-black;
    line-height: 28px;
    margin-top: 0;

    @media (max-width: $media-mobile-width) {
      font-size: 14px;
    }
  }

  img {
    object-fit: contain;
    width: 465px;
    height: 327px;
    margin-bottom: 0;
    padding-bottom: 0;

    @media (max-width: 1000px) {
      width: 363px;
      height: 255px;
    }
    @media (max-width: $media-small-tablet-width) {
      padding: 20px 0;
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
