@import "../../assets/scss/variables";

.CustomizationOption {
  width: 94px;
  height: 41px;
  display: flex;
  padding: 0;

  &:hover {
    border: 4px solid $color-secondary-s3;
    width: 86px;
    height: 33px;
  }
}

.selected {
  display: flex;
  border: 4px solid $color-secondary-s1;
  width: 86px;
  height: 33px;
}
