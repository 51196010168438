@import "src/assets/scss/variables";

.banner {
  display: flex;
  align-items: center;
  border: none;
  min-width: 100%;
  position: relative;

  .image {
    border: none;
    flex: 0.5;

    img {
      vertical-align: middle;
      width: 680px;
      height: 400px;
      object-fit: cover;

      @media (max-width: $media-tablet-width) {
        width: 100%;
        height: 413px;
      }

      @media (max-width: $media-mobile-width) {
        height: 389px;
      }
    }
    @media (max-width: $media-tablet-width) {
      flex: 1;
    }
  }
  .content {
    border: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    text-align: center;

    .contentDisplay {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 50px;

      @media (max-width: 1270px) {
        margin-left: 30px;
      }

      @media (max-width: $media-tablet-width) {
        flex-direction: row;
        justify-content: flex-start;
        margin: 0;
      }
    }

    @media (max-width: $media-tablet-width) {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
      bottom: 28px;
      max-height: 216px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 15px 30px 20px 30px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    @media (max-width: $media-mobile-width) {
      padding: 15px;
      bottom: 18px;
    }
    @media (max-width: 375px) {
      padding: 10px;
    }
  }
  .participateBannerContent {

    @media (max-width: $media-tablet-width) {
      max-height: 216px;
    }

    @media (max-width: 346px) {
      max-height: 260px;
    }
  }
}
