// remove all poses here
:global(#pose-pointing) {
  display: none;
}
:global(#pose-books) {
  display: none;
}
:global(#pose-celebrating) {
  display: none;
}
:global(#pose-pockets) {
  display: none;
}


//blend in the relevant pose
.pose_0 {
  :global(#pose-pointing) {
    display: block;
  }
}

.pose_1 {
  :global(#pose-books) {
    display: block;
  }
}

.pose_2 {
  :global(#pose-celebrating) {
    display: block;
  }
}

.pose_3 {
  :global(#pose-pockets) {
    display: block;
  }
}