@import "../../assets/scss/variables";

$media-mobile-width: 560px;

.BlogEvent {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: $sans-serif-regular;
  margin-left: 40px;
  margin-right: 40px;

  @media (max-width: $media-tablet-width) {
    margin-left: 0;
    margin-right: 0;
  }

  .blog {
    color: $color-text-subheading;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: 13px;
    padding-bottom: 11px;

    @media (max-width: $media-tablet-width) {
      display: none;
    }
  }

  .sideBySide {
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media (max-width: $media-tablet-width) {
      position: relative;
    }

    .imageFrame {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      justify-content: center;
      max-height: 339px;

      .image {
        object-fit: cover;
      }

      @media (max-width: $media-tablet-width) {
        order: 1;
        max-height: 413px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 735px;
      flex: 1;

      .title {
        color: $color-text-heading;
        font-weight: $font-weight-bold;
        font-size: 42px;
        padding-bottom: 20px;

        @media (max-width: $media-tablet-width) {
          font-size: 30px;
        }

        @media (max-width: $media-mobile-width) {
          font-size: 24px;
        }
      }

      .description {
        color: $color-text-body;
        font-weight: $font-weight-regular;
        line-height: 28px;
        max-height: 104px;
        margin-bottom: 10px;

        p {
          margin: 0;
          padding: 0;
          font-family: $sans-serif-regular;
          font-weight: $font-weight-regular;
          line-height: 28px;
          color: $color-text-body;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;

          @media (max-width: $media-tablet-width) {
            font-size: 16px;
            line-height: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
          }

          @media (max-width: $media-mobile-width) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }

      .details {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1 1 auto;
        gap: 10px;
      }
      
      .button {
        button {
          @media (max-width: $media-mobile-width) {
            height: 48px;
            width: 100%;
            min-width: fit-content;
          }
        }
      }

      @media (max-width: $media-tablet-width) {
        order: 2;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        left: 0;
        right: 0;
        bottom: 10px;
        max-width: 100%;
        padding: 20px 40px;
        min-height: 216px;
      }

      @media (max-width: $media-mobile-width) {
        padding: 20px;
      }
    }
  }
}

.iconWithText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .label {
    font-size: 12px;
    color: $color-text-body;
    font-weight: $font-weight-regular;
  }
}
