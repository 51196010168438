@import "src/assets/scss/variables";

$height: 98px;
$mobile-height: $height;
$media-very-small-width: 515px;

.header {
  background: $color-header-background;
  display: flex;
  position: relative;
  height: $height;
  min-width: 278px; // if your phone is smaller than this … there's not much I can do anymore
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition: 0.4s;

  @media (max-width: $media-tablet-width) {
    background-color: $color-white;
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    .loginButton {
      display: flex;
      @media (max-width: $media-tablet-width) {
          display: none;
        }
    }
  }

  .ycg {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0 20px 0 41px;

    .logo {
      display: flex;
      max-height: 65px;
      cursor: pointer;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;

      @media (max-width: $media-tablet-width) {
        max-height: 61px;
      }

      @media (max-width: $media-mobile-width) {
        max-height: 40px;
      }
    }
  }

  .loggedInMenu {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    gap: 20px;
    padding-right: 20px;
    height: auto;

    .dropdownMenu {
      color: $color-secondary-s1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 5px;

      .dropdownContainer {
        padding-top: 5px;
        svg {
          color: $color-secondary-s1;
        }
      }
    }

    .homeIcon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      color: $color-secondary-s1;
    }

  }

  .menu {
    display: flex;
    flex-direction: row;
    background-color: $color-header-background;
    overflow: hidden;
    align-items: center;
    gap: 30px;
    padding-right: 30px;
    height: auto;

    .loginMenuEntry {
      display: none;
      @media (max-width: $media-tablet-width) {
        display: flex;
        margin-top: 12px;
        cursor: pointer;
      }
    }

    .menuEntry {
      margin-top: 12px;
      cursor: pointer;
    }
  }

  .hamburgerButton {
    height: $mobile-height;
    width: 0;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }
}

.clickAway {
  width: 0;
  height: 0;
}

.headerWrapper {

  @media (max-width: $media-tablet-width) {
    .menu {
      gap: 15px;
      padding-right: 15px;
    }
  }

  // what happens when the header gets too small to display everything
  @media (max-width: $media-tablet-width) {

    // when the burger button is pressed
    &.expanded {

      .header {
        height: auto;

        .menu {
          position: fixed;
          top: $height;
          bottom: 10px;
          right: 0;
          left: 0;
          overflow-y: scroll;
          height: unset;
          padding-top: 21px;
          border-bottom: 10px solid $color-secondary-s3;
          background-color: $color-header-background;
        }
      }

      .clickAway {
        height: 1000px;
        width: 100%;
        order: 5;
      }
    }

    .header {
      height: $mobile-height;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .hamburgerButton {
        z-index: 3 !important;
        width: $mobile-height;
        order: 3;
      }

      .login {
        top: 0;
        right: 0;
        order: 2;
        margin: 0;
      }

      .hidden {
        margin-top: -$height;
      }

      .menu {
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        height: 0;
        flex-basis: 100%;
        order: 4;
        flex-direction: column !important;
        gap: 15px;
        align-items: center;
        padding-top: 0;
        padding-left: 20px;
        padding-right: 40px;

        .menuEntry {
          margin-top: 0;
        }
      }

      .ycg {
        order: 1;
        height: $mobile-height !important;
        justify-content: flex-start;
        padding: 0;
        margin-left: 40px;

        @media (max-width: $media-tablet-width) {
          margin-left: 20px;
        }

        .logo {
          height: 65px !important;
          -moz-transition-duration: 0.4s;
          -webkit-transition-duration: 0.4s;
          -o-transition-duration: 0.4s;
          transition-duration: 0.4s;
        }
      }
    }

    @media (max-width: $media-very-small-width) {
      .ycg {
        overflow: hidden;

        .logo {
          object-fit: cover;
        }
      }
    }
  }
}
