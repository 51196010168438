
.jacket_0 {
  //clothing
  --color-clothing-gradient-0: #5fb5c1; //jacket
  --color-shirt-gradient-0: #b3e6bc; //shirt (main)
  --color-shirt-gradient-1: #8eaa90; //shirt (fold)
}

.jacket_1 {
  //clothing
  --color-clothing-gradient-0: #315426; //jacket
  --color-shirt-gradient-0: #e3e5e4; //shirt (main)
  --color-shirt-gradient-1: #c3c6c5; //shirt (fold)
}

.jacket_2 {
  //clothing
  --color-clothing-gradient-0: #ebe324; //jacket
  --color-shirt-gradient-0: #010202; //shirt (main)
  --color-shirt-gradient-1: #010202; //shirt (fold)
}
