@import "../../../assets/scss/variables";

$media-small-tablet-width: 400px;

.commonTitle {
  color: $color-text-heading;
  font-weight: $font-weight-bold;
  font-size: 42px;
  padding-bottom: 20px;
}

.commonDescription {
  color: $color-text-body;
  font-weight: $font-weight-regular;
  line-height: 28px;
  padding-bottom: 11px;
  text-align: justify;
  a {
    text-decoration: none;
  }
}

.commonImage {
  .commonImg {
    max-width: 100%;
    height: auto;
  }
}

.BlogView {

  .breadcrumb {
    padding-left: 40px;
    padding-top: 20px;

    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
      padding-right: 10px;
    }
  }

  .BlogViewWrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    font-family: $sans-serif-regular;
    margin-right: 20px;
    margin-left: 40px;
    max-width: 60%;
    margin-top: 40px;

    @media (max-width: $media-tablet-width) {
      max-width: 90%;
      flex-wrap: wrap;
      margin-left: 20px;
    }

    .blog {
      color: $color-text-subheading;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      font-size: 13px;
      padding-bottom: 11px;
    }

    .sideBySide {
      .image {
        @extend .commonImage;

        img {
          @extend .commonImg;
        }
      }

      .content {
        .title {
          @extend .commonTitle;

          @media (max-width: $media-tablet-width) {
            font-size: 30px;
          }

          @media (max-width: $media-small-tablet-width) {
            font-size: 20px;
          }
        }

        .description {
          @extend .commonDescription;

          @media (max-width: $media-tablet-width) {
            font-size: 16px;
            line-height: 24px;
          }

          @media (max-width: $media-small-tablet-width) {
            font-size: 14px;
            line-height: 21px;
          }
        }

        .details {
          display: flex;
          flex-direction: row;
          padding-bottom: 20px;
          flex: 1 1 auto;
          gap: 10px;
        }
      }
    }

    .paragraphWrapper {
      .header {
        @extend .commonTitle;
        margin-top: 20px;
        font-size: 30px;
        font-weight: $font-weight-medium;

        @media (max-width: $media-tablet-width) {
          font-size: 24px;
        }

        @media (max-width: $media-small-tablet-width) {
          font-size: 14px;
        }
      }

      .smallHeader {
        color: $color-text-body;
        font-size: 24px;
        font-weight: $font-weight-bold;
        margin-top: 20px;

        @media (max-width: $media-tablet-width) {
          font-size: 16px;
        }

        @media (max-width: $media-small-tablet-width) {
          font-size: 14px;
        }
      }

      .paragraphDescription {
        @extend .commonDescription;
        //margin-bottom: 25px;

        @media (max-width: $media-tablet-width) {
          font-size: 16px;
          line-height: 24px;
        }

        @media (max-width: $media-small-tablet-width) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      .image {
        @extend .commonImage;

        img {
          @extend .commonImg;
        }
      }
    }
  }

  .favoriteBlogViewWrapper {
    margin: 30px 20px 0;
    padding: 20px;
    background-color: $color-primary-p1;

    .heading {
      box-sizing: border-box;
      font-family: $sans-serif-regular;
      font-weight: $font-weight-bold;
      font-size: 24px;
      color: $color-secondary-s1;
      text-align: left;
      line-height: normal;
      margin-bottom: 23px;
    }

    .blogTile {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
    }
  }

  @media (max-width: 1224px) {
    flex-wrap: wrap;
  }
}

.iconWithText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .label {
    font-size: 12px;
    color: $color-text-body;
    font-weight: $font-weight-regular;
  }
}

a {
  color: $color-text-link;
}
