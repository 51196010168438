@import "src/assets/scss/variables";

.breadcrumb {
  padding-left: 40px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media (max-width: $media-tablet-width) {
    padding-left: 20px;
  }
}
