@import "src/assets/scss/variables";
@import "../../components/Mia/Mia.module";

.Dashboard {
  h2{
    color: $color-secondary-s1;
  }
  .relative {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mia {
    z-index: 10;
    position: fixed;
    bottom: 21px;
    right: 21px;
    pointer-events: all;

    .miaButton {
      display: flex;
      align-items: center;
    }

    .miaHandler {
      position: fixed;
      bottom: -290px;
      right: 0;
      padding-right: 21px;
      @media (max-width: $media-mobile-mia-width) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: unset;
        padding-right: 0;
      }
    }
  }

  .content {
    background: $color-primary-p1;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 25px;

    .searchBar {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      padding-bottom: 20px;
      gap: 15px;

      .firstSearchBarEntry {
        flex-grow: 5;

        @media (max-width: $media-tablet-width) {
          width: 100%;
        }
      }

      .preKnowledgeOptions {
        z-index: 2;
        flex-grow: 1;

        @media (max-width: $media-mobile-width) {
          display: none;
        }
      }

      .ageOptions {
        z-index: 3;
        flex-grow: 1;

        @media (max-width: $media-mobile-width) {
          display: none;
        }
      }

      .topicOptions {
        z-index: 4;
        flex-grow: 1;

        @media (max-width: $media-mobile-width) {
          display: none;
        }
      }
    }
  }

  .subject {
    padding-top: 20px;
  }

  .category {
    padding-top: 20px;
  }
}

.carouselWrapper {
  position: relative;
  z-index: 1;
}

.lessonWrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 25px;
  @media (max-width: $media-mobile-width) {
    gap: 0;
  }
}

.resultsTitle {
  font-family: $sans-serif-regular;
  font-size: $font-size-body-1;
  color: $color-grey-1;
  font-weight: $font-weight-regular;
  line-height: 28px;
}
