@import "src/assets/scss/variables";

.MediaPage {
  display: flex;
  flex-direction: column;

  .breadcrumb {
    padding-left: 40px;
    padding-top: 20px;
    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-primary-p1;
    min-height: 100px;
    padding: 35px;
    @media (max-width: $media-tablet-width) {
      padding: 35px 20px;
    }
    .postList {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 857px;
      @media (max-width: $media-tablet-width) {
        max-width: 100%;
      }
    }
  }
}
