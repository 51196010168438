@import "src/assets/scss/variables";

.breadcrumb {
  padding-left: 40px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media (max-width: $media-tablet-width) {
    padding-left: 20px;
  }
}

.OrderPage {
  display: flex;
  justify-content: center;
  max-width: 1366px;
  height: max-content;
  background-color: $color-primary-p1;
  overflow: auto;
  padding: 20px 20px 50px;
  margin-top: 10px;
  @media (max-width: $media-mobile-width) {
    margin-top: 0;
    padding: 0 10px 20px; //top left-right bottom
  }
}

.form {
  width: 751px;
  height: max-content;
  background-color: $color-white;
  margin: 30px auto 0 auto;
  border-radius: 3px;
  box-shadow:0 0 6px rgba(0,0,0,.1);

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .header {
    padding: 50px 50px 0 50px;
    @media (max-width: $media-tablet-width) {
      font-size: 28px;
    }
    @media (max-width: $media-mobile-width) {
      padding: 0 20px;
      font-size: 24px;
    }
    text-align: center;
  }

  .introText {
    padding: 0 50px;
    @media (max-width: $media-mobile-width) {
      padding: 0 20px;
      font-size: 14px;
    }
    white-space: pre-line;
    text-align: center;
  }

  .inputFields {
    width: 86.5%;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: $media-mobile-width) {
      padding: 30px 20px;
    }
  }

  .inputField {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .withError {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .consent {
    span {
      @media (max-width: $media-mobile-width) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .text {
    color: $color-text-body;
    font-size: $font-size-tooltip;
    font-weight: $font-weight-regular;
    font-family: $sans-serif-regular;
    font-style: unset;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: left;
  }

  .buttonContainer {
    width: 100%;
    border-top: 1px solid $color-grey-4;

    .button {
      width: max-content;
      margin: 30px auto;
      padding-bottom: 30px;
      @media (max-width: $media-mobile-width) {
        margin: 30px 20px;
        width: unset;
        button {
          min-width: fit-content;
          width: 100%;
        }
      }
    }
  }
}
