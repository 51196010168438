@import "src/assets/scss/variables";
.courseModalDisplay {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .badgeWithText {
    display: flex;
    justify-content: space-between;
    @media (max-width: $media-mobile-width) {
      position: relative;
      padding: 0 5px;
    }


    .closeIcon{
      color: $color-error-dark;
      cursor: pointer;
      width: 21px;
      height: 21px;
      svg {
        transform: scale(1.6);
      }
    }
  }

  .heading {
    h2 {
      margin-bottom: 0 !important;
      color: $color-secondary-s1;
      @media (max-width: $media-tablet-width) {
        font-size: 28px;
      }
      @media (max-width: $media-mobile-width) {
        font-size: 24px;
        font-weight: $font-weight-medium;
      }
    }
  }

  .leftColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $media-mobile-width) {
      justify-content: center;
      gap: 10px;
    }

    .firstText {
      @media (max-width: 800px) {
        margin: 20px 0;
      }
      @media (max-width: $media-mobile-width) {
        margin-bottom: 130px;
        margin-top: 0;
      }
    }

    p {
      @media (max-width: $media-mobile-width) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .badge {
    display: flex;
    justify-content: flex-end;
    @media (max-width: $media-mobile-width) {
      position: absolute;
      width: 163px;
      height: 169px;
      top: 100px;
      left: 25%;
    }
  }

  .courseTileWrapper {
    padding: 25px 0;
    .courseTile {
      display: flex;
      flex-wrap: nowrap;
      gap: 25px;
      @media (max-width: $media-mobile-width) {
        gap:0;
      }
    }
  }

  .dashboardButton {
    display: flex;
    justify-content: flex-end;
    @media (max-width: $media-mobile-width) {
      justify-content: center;
      button {
        min-width: unset;
        width: 100%;
      }
    }
  }
}
