@import "src/assets/scss/variables";

$media-width: 834px;
$media-small-width: 380px;

.registrationPageHeader {
  padding: 10px 0;

  .breadcrumb {
    padding-left: 40px;
    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
    @media (max-width: $media-small-width) {
      display: none;
    }
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    h1:first-child {
      padding-right: 10px;
    }

    h1 {
      @media (max-width: $media-width) {
        margin: 0;
      }

      @media (max-width: 470px) {
        font-size: 32px;
      }

      @media (max-width: $media-small-width) {
        font-size: 24px;
        line-height: 40px;
      }
    }

    @media (max-width: $media-width) {
      padding: 20px;
    }

    @media (max-width: $media-small-width) {
      padding: 0 0 20px 0;
      justify-content: flex-start;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
    padding: 0 20px;

    @media (max-width: 672px) {
      gap: 50px;
    }

    @media (max-width: 572px) {
      gap: 25px;
    }

    @media (max-width: $media-small-width) {
      justify-content: flex-start;
    }

    span {
      padding: 4px 8px;
      border-radius: 100px;
      font-size: small;
      color: $color-white;
      font-weight: 900;
    }

    p {
      font-size: 14px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .userDataPage {
    .userData {
      span {
        background-color: $color-secondary-s1;
      }

      p {
      font-weight: $font-weight-bold;
      color: $color-secondary-s1;
      }
    }
    .personalization{
      span {
        background-color: $color-grey-2;
      }

      p {
        color: $color-grey-2;
      }
    }
  }

  .personalizationPage {
    .userData {
      span {
        background-color: $color-secondary-s1;
      }

      p {
        color: $color-grey-2;
      }
    }
    .personalization{
      span {
        background-color: $color-secondary-s1;
      }

      p {
        font-weight: $font-weight-bold;
        color: $color-secondary-s1;
      }
    }
  }

  .loginPage {
    .userData {
      span {
        background-color: $color-secondary-s1;
      }

      p {
        color: $color-grey-2;
      }
    }
    .personalization{
      span {
        background-color: $color-grey-2;
      }

      p {
        color: $color-grey-2;
      }
    }
  }

  @media (max-width: $media-small-width) {
    padding: 0 20px;
  }
}
