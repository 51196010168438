@import "src/assets/scss/variables";

.breadcrumb {
  padding-left: 40px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media (max-width: $media-tablet-width) {
    padding-left: 20px;
  }
}
.newsletter{
  display: flex;
  justify-content: center;
  margin-top: 10px;

  iframe {
    min-height: 67vh;
    width: 100%;
    border: none;
  }
}

.button {
  width: max-content;
  margin: 30px auto 30px auto;
  @media (max-width: $media-mobile-width) {
    width: unset;
    button {
      min-width: fit-content;
      width: 100%;
    }
  }
}
