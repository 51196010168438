@import "../../assets/scss/variables";

$lines-to-show: 4;

.JobTile {
  cursor: pointer;
  background-color: white;
  width: 300px;
  height: 136px;
  display: flex;
  flex-direction: column;
  font-family: $sans-serif-regular;
  overflow: hidden;
  text-decoration: none;
  padding: 20px;
  gap: 10px;
  //min-height: 136px;

  /* slightly transparent fallback */
  &:hover {
    border-bottom: 8px solid $color-secondary-s1;

    .title {
      font-weight: $font-weight-bold;
    }

    font-family: $sans-serif-regular;
    font-style: normal;
  }
}

.selected {
  border-bottom: 8px solid $color-secondary-s1;
}

.unSelected {
  border-bottom: 8px solid white;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  vertical-align: middle;
  color: $color-secondary-s1;
  font-family: $sans-serif-regular;
  font-size: $font-size-body-lead;
}

.rating {
  display: flex;
  align-items: center;
  //line-height: 70px;
  gap: 10px;
}

.text {
  font-family: $sans-serif-regular;
  font-size: $font-size-body-2;
  color: $color-text-body;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.icon {
  color: $color-secondary-s1;
}
