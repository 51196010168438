@import "src/assets/scss/variables";

.Footer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 236px;
  background-color: white;
  border-top: $color-footer-line solid 3px;

  a {
    text-decoration: none;
  }

  .companies {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    img {
      height: 220px;
    }
  }


  .links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //padding-top: 48px;
    gap: 30px;

    .link {
      text-align: center;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0 10px;

        p {
          padding-left: 10px;
          font-family: $sans-serif-regular;
          color: $color-footer-links;
          font-size: $font-size-body-2;
        }
      }
    }
  }
}
