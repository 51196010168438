@import 'src/assets/scss/variables';

.PrivacyNoticePage {
  padding: 0 50px 50px 50px;
  @media (max-width: $media-tablet-width) {
    padding: 0 20px 50px 30px;
  }

  div {
    border: 0;
  }

  h1 {
    @media (max-width: $media-tablet-width) {
      font-size: $font-size-h3;
    }
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-h4;
    }
  }

  h2 {
    @media (max-width: $media-tablet-width) {
      font-size: $font-size-h4;
    }
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-h6;
    }
  }

  p {
    font-family: $sans-serif-regular;
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: 16px;
    color: $color-text-body;
    line-height: 28px;
    height: max-content;
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-body-2;
      line-height: 26px;
    }
  }

  ul {
    font-family: $sans-serif-regular;
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: 16px;
    color: $color-text-body;
    line-height: 28px;
    @media (max-width: $media-mobile-width) {
      font-size: $font-size-body-2;
      line-height: 26px;
      padding-left: 30px;
    }
  }

  .padding {
    padding-left: 50px;
    @media (max-width: $media-tablet-width) {
      padding-left: 0;
    }
  }

  .bold {
    font-weight: $font-weight-bold;
  }

  span {
    display: block;
  }

  .list {
    li {
      font-family: $sans-serif-regular;
      font-weight: $font-weight-regular;
      font-style: normal;
      font-size: 16px;
      color: $color-text-body;
      line-height: 28px;
    }
  }

  a {
    text-decoration: none;
    color: $color-secondary-s1;
  }

  .breadcrumb {
    padding-top: 20px;
    span {
      display: inline;
    }
  }
}
