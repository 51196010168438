@import "../../../assets/scss/variables";


.ratingsBanner {
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(2px) saturate(5);
  -webkit-backdrop-filter: blur(2px) saturate(5);
  background-color: rgba(255, 255, 255, 0.85);
  padding: 2px 5px;
  gap: 10px;
  .stars {
    display: flex;
    flex-direction: row;
    color: $color-secondary-s2;
  }
  .reviews {
    display: table;
    .text {
      display: table-cell;
      vertical-align: middle;
    }
  }

}

.star {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
