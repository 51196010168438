@import "src/assets/scss/variables";

$media-font-size: 800px;
$media-switch-to-column-width: 600px;

.UpcomingEvent {
  background-color: $event-background-color;

  .content {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 27px 47px;
    @media (max-width: $media-tablet-width) {
      flex-direction: column;
      position: relative;
    }
    @media (max-width: $media-mobile-width) {
      padding: 27px 20px;
      gap: 20px;
    }

    .imageFrame {
      display: flex;
      flex-direction: column;
      flex: 0.9;
      overflow: hidden;
      justify-content: center;
      max-width: 474px;
      max-height: 340px;
      @media(max-width: $media-tablet-width) {
        max-width: 220px;
        max-height: 140px;
        justify-content: start;
      }
      @media (max-width: $media-switch-to-column-width) {
        max-width: fit-content;
        max-height: fit-content;
      }
      @media(max-width: $media-mobile-width) {
        max-width: fit-content;
        max-height: fit-content;
      }

      .image {
        object-fit: cover;
      }
    }

    .info {
      font-family: $sans-serif-regular;
      display: flex;
      flex-direction: column;
      flex: 1.1;
      padding-top: 10px;
      @media (max-width: $media-tablet-width) {
        padding-top: 0;
      }

      .titleDetails {
        @media (max-width: $media-tablet-width) {
          position: absolute;
          top: 30px;
          left: 290px;
          padding-right: 10px;
        }
        @media (max-width: $media-switch-to-column-width) {
          position: static;
        }

        .title {
          color: $color-text-heading;
          font-weight: $font-weight-bold;
          font-size: $font-size-h3;
          padding-bottom: 20px;
          display: flex;
          flex: 0 1 auto;
          letter-spacing: 0.16px;
          @media (max-width: $media-tablet-width) {
            font-size: $font-size-h4;
            font-weight: $font-weight-bold;
            padding-bottom: 10px;
          }
          @media (max-width: $media-font-size) {
            font-size: $font-size-body-lead;
          }
          @media (max-width: $media-mobile-width) {
            font-size: $font-size-body-1;
            font-weight: $font-weight-medium;
          }
        }

        .details {
          display: flex;
          flex-direction: row;
          gap: 24px;
          padding-bottom: 14px;
          flex: 0 1 auto;
          :nth-child(2) {
            color: $color-text-body;
            font-size: $font-size-body-2;
          }
          @media (max-width: $media-tablet-width) {
            flex-wrap: wrap;
          }
          @media (max-width: $media-mobile-width) {
            gap: 5px;
            flex-direction: column;
            div {
              font-size: $font-size-tooltip;
            }
          }
        }
      }

      .description {
        color: $color-text-body;
        font-weight: $font-weight-regular;
        line-height: 24px;
        padding-bottom: 10px;
        display: flex;
        flex: 1 1 auto;
        font-size: $font-size-body-1;
        a {
          text-decoration: none;
        }
        @media (max-width: $media-tablet-width) {
          text-align: justify;
          line-height: 24px;
        }
        @media (max-width: $media-mobile-width) {
          font-size: $font-size-body-2;
          line-height: 21px;
        }
      }

      .button {
        display: flex;
        flex: 0 1 auto;
        @media (max-width: $media-tablet-width) {
          justify-content: left;
          padding-top: 30px;
          button {
            height: 49px;
          }
        }
      }
    }
  }
}

.iconWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  color: $color-text-link;

  .text {
    font-size: $font-size-tooltip;
  }
}
