@import "src/assets/scss/variables";

html {
  width: 100vw;
  overflow-x: hidden;
}

.wrapper {
  margin: 0 auto;
  width: 1366px;

  .hidden {
    margin-top: 0;
  }

  .header {
    -webkit-backface-visibility: hidden; // seems to fix the issue with the fixed position not working on mobile browsers
    position: fixed;
    width: inherit;
    z-index: 5;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    @media (max-width: $media-tablet-width) {
      z-index: 11;
    }
  }

  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: $media-tablet-width) {
    // the header gets hidden on scroll down and reappears on scroll up
    .hidden {
      margin-top: -100px;
      background-color: yellow;
    }
    .content {
      padding-top: 100px;
    }
  }
}

.content {
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  padding-top: 98px;
  // little trick to make the footer always appear on the bottom of the page, even when the content does not fill the whole page
  min-height: calc(100vh - 270px - 98px - 4px);
}

.footer {
  width: 100%;
}

.LegalPrivacyNoticeBar {
  margin-top: 30px;
  width: 100%;
}
