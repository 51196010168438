@import "src/assets/scss/variables";
@import "../../components/Mia/Mia.module";

$media-small-tablet-width: 688px;

.CoursePage {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: $media-small-tablet-width) {
    padding: 0 10px;
  }

  .breadcrumb {
    padding-left: 20px;
    @media (max-width: $media-mobile-width) {
      padding-left: 0;
    }
  }

    .courseContent {
      margin-top: 40px;
      margin-bottom: 25px;
      position: relative;

      @media (max-width: $media-mobile-width) {
        padding: 0;
        margin-bottom: 25px;
      }

      .overlay {
        content: '';
        z-index: 1;
        position: absolute;
        top: 50px;
        bottom: 10px;
        right: 20px;
        left: 20px;
        background-color: rgba(51, 51, 51, 0.94);

        @media (max-width: 600px) {
          right: 16px;
          left: 30px;
        }

        @media (max-width: $media-mobile-width) {
          top: 30px;
          right: 0;
          bottom: 25px;
          left: 0;
        }
      }

      .videoOverlay {
        @media (max-width: 650px) {
          right: 0;
          left: 0;
        }

        @media (max-width: $media-mobile-width) {
          top: 25px;
          bottom: 10px;
        }
      }

      .overlayButton {
        position: absolute;
        z-index: 2;
        display: flex;
        top: 100px;
        left: 50px;
        justify-content: flex-start;
        text-align: center;

        button {
          @media (max-width: $media-mobile-width) {
            min-width: unset!important;
          }
        }

        @media (max-width: $media-mobile-width) {
          top: 127px;
          max-width: 70%;
        }
      }

      .video {
        min-width: 278px;
        width: 100%;
        background: $color-primary-p1;
        aspect-ratio: 1366 / 763;

        video {
          width: 92%;
          display: block;
          margin: 35px auto;
          aspect-ratio: 16 / 9;
          padding: 40px 20px;
          @media (max-width: $media-mobile-width) {
            margin: 15px auto;
            width: 88%;
          }
        }

        .videoFinishButton {
          display: flex;
          justify-content: center;
          text-align: center;
          padding: 0 20px 20px 20px;
        }

        .videoFinished {
          font-family: $sans-serif-regular;
          font-size: 20px;
          font-weight: $font-weight-medium;
          color: $color-secondary-s1;
          padding-bottom: 20px;
          text-align: center;

          @media (max-width: $media-mobile-width) {
            width: 100%;
          }
        }
      }

      .externalCourse {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-left: 20px;
        margin-bottom: 25px;
        width: 380px;

        @media (max-width: $media-small-tablet-width) {
          margin-left: 5px;
          width: 70%;
          padding-right: 10px;
        }

        @media (max-width: $media-mobile-width) {
          margin-left: 0;
          width: 100%;
        }

        .externalCourseButtons {
            button {
              width: 100%;
              min-width: fit-content!important;
            }
        }

        .externalCourseFinished {
          font-family: $sans-serif-regular;
          font-size: 20px;
          font-weight: $font-weight-medium;
          color: $color-secondary-s1;
          width: max-content;

          @media (max-width: $media-mobile-width) {
            text-align: center;
            width: 100%;
          }
        }
      }

      .internalCourse {
        min-width: 278px;
        height: 100%;
        background: $color-primary-p1;
        aspect-ratio: 16/9;
        padding: 0 20px;

        @media (max-width: $media-mobile-width) {
          padding: 0;
          background-color: $color-white;
        }

        .internalCourseButton {
          display: none;
          @media (max-width: $media-mobile-width) {
            display: flex;
            margin: 20px 0;
            button {
              min-width: fit-content;
              width: 100%;
            }
          }
        }

        .landscapeInfoBanner {
          display: none;

          @media (max-width: $media-mobile-width) {
            display: flex;
            div {
              line-height: 20px;
            }
          }
        }

        iframe {
          display:block;
          border: 0;
          width: 95%;
          height: 90%;
          margin: 35px auto 0 auto;
          padding: 40px 20px;

          @media (max-width: $media-mobile-width) {
            display: none;
          }
        }

        .internalCourseFinishButton {
          display: flex;
          justify-content: center;
          text-align: center;
          padding: 0 0 20px;
        }

        .internalCourseFinished {
          font-family: $sans-serif-regular;
          font-size: 20px;
          font-weight: $font-weight-medium;
          color: $color-secondary-s1;
          padding-bottom: 20px;
          text-align: center;

          @media (max-width: $media-mobile-width) {
            width: 100%;
          }
        }
      }
      @media (max-height: 780px) {
        .internalCourse {
          aspect-ratio: 16 / 8.8;
        }
      }
      @media (max-height: 760px) {
        .internalCourse {
          aspect-ratio: 16 / 8.4;
        }
      }
      @media (max-height: 740px) {
        .internalCourse {
          aspect-ratio: 16 / 8.2;
        }
      }
      @media (max-height: 720px) {
        .internalCourse {
          aspect-ratio: 16 / 8;
        }
      }
    }
}


.confirmationModal {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 700px;

  .modalHeaders {
    h4 {
      margin-top: 0;
    }
    p {
      font-family: $sans-serif-regular;
      text-align: left;
      line-height: 24px;
      font-size: $font-size-body-1;
      margin-bottom: 30px;
      @media (max-width: $media-mobile-width) {
        line-height: 21px;
        font-size: 16px;
      }
    }
  }

  .modalButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    @media (max-width: $media-mobile-width) {
      justify-content: space-between;
      button {
        height: 48px;
      }
    }
    @media (max-width: $media-mobile-width) {
      flex-wrap: wrap;
      button {
        min-width: max-content;
        width: 100%;
      }
    }
  }
}
