@import "src/assets/scss/variables";

  .dropdownButton {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    display: flex;
    cursor: pointer;

    &:hover {
      div {
        text-shadow: -.25px -.25px 0 $color-text-body, .25px .25px $color-text-body;
      }
    }

    .option {
      margin: 0;
      flex: 1;
      display: flex;
      align-items: center;
      width: auto;
      height: 100%;
      font-family: $sans-serif-regular;
      font-size: $font-size-body-1;
      color: $color-text-body;
      line-height: 16px;
      padding: 0 20px;

      span {
        padding: 3px 0;
      }
    }
  }
