@import "src/assets/scss/variables";

$media-before-tablet-breakpoint: 1224px;
$media-small-tablet-width: 688px;

.courseIntro {
  width: 97%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: $media-before-tablet-breakpoint) {
    flex-direction: column;
  }

  @media (max-width: $media-mobile-width) {
    width: 100%;
  }

  .courseDescription {
    width: 42%;

    @media (max-width: $media-tablet-width) {
      width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (max-width: $media-tablet-width) {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
    }

    @media (max-width: $media-small-tablet-width) {
      width: 70%;
    }

    @media (max-width: $media-mobile-width) {
      width: 100%;
      justify-content: center;
    }

    .courseInfoblock {
      height: 248px;
      margin-top: 74px;

      @media (max-width: $media-before-tablet-breakpoint) {
        margin-top: 10px;
      }

      @media (max-width: $media-tablet-width) {
        height: 194px!important;
      }

      @media (max-width: $media-small-tablet-width) {
        margin-top: 0;
        width: 100%;
        height: auto!important;
      }
    }

    .courseImage {
      max-height: 248px;
      margin-top: 74px;

      @media (max-width: $media-before-tablet-breakpoint) {
        margin-top: 10px;
      }

      @media (max-width: $media-small-tablet-width) {
        margin: 0;
        max-height: 100%;
      }

      img {
        max-height: 248px;
        box-sizing: border-box;

        @media (max-width: $media-tablet-width) {
          max-width: 297px;
        }

        @media (max-width: $media-small-tablet-width) {
          height: auto;
          max-height: fit-content;
          max-width: 100%;
        }
      }
    }
  }
}

