@import "~@fontsource/roboto";
@import "~@fontsource/roboto/300.css";
@import "~@fontsource/roboto/500.css";
@import "~@fontsource/roboto/700.css";

//font family
$sans-serif-regular: "Roboto", Arial, sans-serif;

//font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

//font sizes
$font-size-tooltip: 0.75rem;		//12px
$font-size-body-2: 0.875rem;		//14px
$font-size-body-1: 1rem;			//16px
$font-size-body-lead: 1.125rem;	//18px
$font-size-h6: 1.25rem;		//22px
$font-size-h4: 1.5rem;		//24px
$font-size-h3: 1.8rem;		//28px
$font-size-h2: 2.125rem;	//34px
$font-size-h1: 2.5rem;	//40px
