@import "../../assets/scss/variables";


.selectableTilesCategory {

h2 {
  @media (max-width: $media-tablet-width) {
    font-weight: $font-weight-medium;
    font-size: $font-size-h3;
  }

  @media (max-width: $media-mobile-width) {
    font-size: $font-size-h4;
  }
}

.selectableTile {
  display: flex;
  flex-direction: column;

  img {
    height: 205px;
    width: 315px;
    object-fit: initial;
  }

  button {
    @media (max-width: 350px) {
      max-width: 275px;
      max-height: 182px;
    }

    div {
      @media (max-width: 350px) {
        bottom: -12px;
      }
    }
  }
}

.toggleButton {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  button {
    @media (max-width: $media-tablet-width) {
      height: unset!important;
      min-height: fit-content;
    }
    @media (max-width: $media-mobile-width) {
      font-size: 16px!important;
    }
  }

  h2 {
    padding-bottom: 10px;
  }
}
}
