@import "../../assets/scss/variables";

$line-height: 20px;
$lines-to-show: 4;

.ContentTile {
  cursor: pointer;
  background-color: white;
  width: 315px;
  height: 352px;
  display: flex;
  flex-direction: column;
  font-family: $sans-serif-regular;
  overflow: hidden;
  text-decoration: none;

  /* slightly transparent fallback */
  &:hover {
    border-bottom: 8px solid $color-secondary-s1;
    .banner {
      .title {
        font-weight: $font-weight-bold;
        padding: 0 12px 0 12px;
      }
      background-color: rgba(255, 255, 255, 0.85);
      font-family: $sans-serif-regular;
      font-style: normal;
    }
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
    &:hover {
      border-bottom: 8px solid $color-secondary-s1;
      .banner {
        .title {
          font-weight: $font-weight-bold;
          padding: 0 12px 0 12px;
        }
        background-color: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(4px) saturate(5);
        -webkit-backdrop-filter: blur(4px) saturate(5);
        font-family: $sans-serif-regular;
        font-style: normal;
      }
    }
  }
}

.selected {
  border-bottom: 8px solid $color-secondary-s1;
}

.unSelected {
  border-bottom: 8px solid white;
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 205px;
  position: relative;
  overflow: hidden;

  .finishedBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: $color-primary-p1;
    top: 50px;
    svg {
      width: 108px!important;
      padding-top: 7px;
    }
  }


  .imageFrame {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    justify-content: center;

    .image {
      object-fit: cover;
      min-height: 205px;
    }
  }

  .ratings {
    position: absolute;
    top: 7px;
    right: 14px;
  }

  .workshopBanner {
    position: absolute;
    top: 5px;
    left: 0;
    font-size: xx-small;
    padding: 4px 15px;
    color: $color-secondary-s1;
    text-transform: uppercase;
    backdrop-filter: blur(2px) saturate(5);
    -webkit-backdrop-filter: blur(2px) saturate(5);
    background-color: rgba(255, 255, 255, 0.85);
  }

  .banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: table;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    .title {
      display: table-cell;
      vertical-align: middle;
      padding: 0 14px 0 14px;
      color: $color-secondary-s1;
      font-family: $sans-serif-regular;
      font-size: $font-size-body-lead;
    }
  }
}

.bottom {
  background-color: white;
  height: 155px;

  .targetAudience {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: x-small;
    gap: 14px;
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;

    .ageGroup {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .header {
        padding-right: 5px;
        color: $color-text-link;
        line-height: 22px;
        font-size: $font-size-tooltip;
      }
    }

    .difficulty {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .header {
        text-align: left;
        padding-right: 5px;
        line-height: 22px;
        color: $color-text-link;
        font-size: $font-size-tooltip;
      }
    }
  }

  .description {
    margin: 14px;
    line-height: 20px;
    p {
      font-family: $sans-serif-regular;
      font-size: $font-size-body-2;
      color: $color-text-body;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: $lines-to-show;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .details {
      display: flex;
      flex-direction: row;
      padding-top: 12px;
      font-size: $font-size-tooltip;
      align-items: center;
      gap: 20px;
    }
  }

  .externalWorkshop {
    margin: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .label {
    padding-top: 3px;
    vertical-align: top;
  }
}
