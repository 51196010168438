
.accessories_0 {
  //accessories
  --color-backpack-gradient-0: #7a394f;
  --color-backpack-gradient-1: #6c6f56; //top part and straps
}

.accessories_1 {
  //accessories
  --color-backpack-gradient-0: #bca11b;
  --color-backpack-gradient-1: #6c7057; //top part and straps
}

.accessories_2 {
  --color-backpack-gradient-0: #cc6815;
  --color-backpack-gradient-1: #6c7057; //top part and straps
}
