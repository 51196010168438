@import "src/assets/scss/variables";
@import "../../components/Mia/Mia.module";

.typeformFeedback {
  margin-top: 35px;
  padding: 0 10px;

  .breadcrumb {
    padding-left: 20px;
    @media(max-width: $media-tablet-width) {
      padding-left: 10px;
    }
    @media(max-width: $media-mobile-width) {
      padding-left: 0;
    }
  }

  .button {
    display: none;
    @media(max-width: $media-tablet-width) {
      display: flex;
      padding: 20px 5px;
    }
    @media(max-width: $media-mobile-width) {
      padding: 20px 0;
    }

    button {
      @media(max-width: $media-mobile-width) {
        min-width: fit-content;
        width: 100%;
        padding: 0;
      }
    }
  }

  .feedback {
    display: flex;
    justify-content: center;
    margin: 30px 20px;
    background-color: rgba(59, 180, 180, 0.1);
    padding: 30px 10px;
    overflow: hidden;

    @media (max-width: $media-tablet-width) {
      display: none;
    }
  }
}
