@import "src/assets/scss/variables";

.registrationPersonalizationPage {

  .notificationBanner {
    margin-top: 10px;
    max-width: 375px;
    margin-left: 40px;
    @media (max-width: $media-tablet-width) {
      margin-left: 20px;
    }
    @media (max-width: $media-mobile-width) {
      padding-right: 20px;
    }
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;

    h2 {
      text-align: center;
      @media (max-width: $media-tablet-width) {
        text-align: left;
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 70px 0;
    gap: 20px;

    button {
      @media (max-width: $media-mobile-width) {
        min-width: max-content;
        width: 100%;
        margin: 0 30px;
      }
    }
  }
}