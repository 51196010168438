@import "../../assets/scss/variables";

.Blog {
  margin-top: 20px;

  .breadcrumb {
    padding-left: 40px;
    padding-bottom: 20px;

    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
  }

  .favoriteBlogWrapper {
    margin-top: 30px;
    padding: 40px 40px 10px 40px;
    background-color: $color-primary-p1;

    @media (max-width: 440px) {
      padding: 40px 20px;
    }

    .heading {
      box-sizing: border-box;
      font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: $color-secondary-s1;
      text-align: left;
      line-height: normal;
      margin-bottom: 23px;
    }

    .blogTile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .simpleBlogWrapper {
    margin: 30px 20px 0;
    padding: 0 20px 20px;
    background-color: white;

    .simpleBlogTile {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
      margin-bottom: 39px;
    }
  }

  .sendingBlog {
    margin-bottom: 40px;
    padding: 10px 40px 60px 40px;
    background-color: $color-primary-p1;

    .sendingBlogDescription {
      a {
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        font-family: $sans-serif-regular;
        color: $color-black;

        span {
          a {
            color: $color-text-link;
          }
        }
      }
    }
  }
}
