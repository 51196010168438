@import "../../assets/scss/variables";

.CustomizationCategory {
  .categoryName {
    font-family: $sans-serif-regular;
    font-weight: $font-weight-bold;
    font-size: $font-size-body-lead;
    color: $color-secondary-s1;
  }

  .options {
    margin-top: 23px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    @media (max-width: 655px) {
      justify-content: center;
    }
  }
}
