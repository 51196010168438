@import "src/assets/scss/variables";

.EventsPage {
  display: flex;
  flex-direction: column;
  .breadcrumb {
    padding-left: 40px;
    padding-top: 20px;

    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
  }
  .headerUpcoming {
    padding-left: 40px;
    h1{
      @media (max-width: $media-tablet-width) {
        font-size: $font-size-h2;
        line-height: 36px;
      }
      @media (max-width: $media-mobile-width) {
        font-size: $font-size-h4;
        font-weight: $font-weight-medium;
      }
    }
    @media (max-width: $media-tablet-width) {
      padding-left: 20px;
    }
  }
  .upcomingList {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .headerPast {
    padding-left: 40px;
    @media (max-width: $media-mobile-width) {
      padding-left: 20px;
    }
    h3 {
      @media (max-width: $media-tablet-width) {
        font-size: $font-size-h4;
        font-weight: $font-weight-bold;
      }
      @media (max-width: $media-mobile-width) {
        font-size: $font-size-body-1;
        font-weight: $font-weight-medium;
      }
    }
  }
  .pastList {
    padding: 0 76px 30px 0;
    @media (max-width: 1366px) {
      padding: 0 40px 30px 0;
    }
    @media (max-width: $media-tablet-width) {
      padding-left: 40px;
    }
    @media (max-width: $media-mobile-width) {
      padding: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
