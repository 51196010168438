@import "../../../../assets/scss/variables";

.chat {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scrollable {
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    scrollbar-color: $color-secondary-s1 white;
    scrollbar-width: thin;
    margin-bottom: 5px;
    margin-right: -8px;
    padding-right: 8px;
    flex: 1;

    @media (max-width: $media-mobile-mia-width) {
      margin-bottom: 50px;
    }
  }

  .chatHistory {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-bottom: 8px;
    flex: auto;

    .avatar {
      display: flex;

      img {
        position: static;
        padding:0;
        width: 24px;
        height: 24px;
      }
    }

    .textCell {
      display: flex;
      align-items: flex-end;
      border-radius: 12px;
      @media (max-width: $media-mobile-mia-width) {
        max-width: 362px;
      }

      .textContent {
        text-align: left;
        font-family: $sans-serif-regular;
        font-weight: $font-weight-regular;
        color: $color-text-body;
        font-size: $font-size-body-2;
        line-height: 20px;
        padding: 10px 10px 10px 10px;
      }
    }

    .miaMessage {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .avatar {
        border-radius: 50px;
        margin-left: 3px;
        width: 24px;
        height: 24px;
        border: 2px solid $color-secondary-s1;
        background-color: $color-white;
        overflow: hidden;
        @media (max-width: $media-mobile-mia-width) {
          width: 35px;
          height: 35px;
        }
        .svg {
          transform: scale(-1, 1) translate(-6px);
          margin-top: -18px;
          margin-left: -12px;
          height: 130px;
          @media (max-width: $media-mobile-mia-width) {
            margin-top: -12px;
            margin-left: -6px;
          }
        }
      }

      .textCell {
        flex: 1;
        margin-left: 12px;
        justify-content: flex-start;
        background-color: $color-primary-p1;
        @media (max-width: $media-mobile-mia-width) {
          max-width: 362px;
        }
      }
    }

    .userMessage {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: $media-mobile-mia-width) {
       margin: 10px 0;
      }

      .avatar {
        margin-right: 3px;
        img {
          outline: 2px solid $color-secondary-s2;
          border-radius: 15px;
        }
        span {
          @media (max-width: $media-mobile-mia-width) {
            font-size: 35px!important;
          }
        }
      }

      .textCell {
        margin-right: 10px;
        background-color: $color-primary-p1;
        @media (max-width: $media-mobile-mia-width) {
          max-width: 362px;
        }
      }
    }
  }

  .textBoxWrapper {
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 95%;
    @media (max-width: $media-mobile-mia-width) {
      position: fixed;
      left: 10px;
      right: 0;
      bottom: 20px;
    }
  }


}

.textBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $color-secondary-s3;
  margin-bottom: -1px;
  height: 40px;
  width: inherit;
  background-color: $color-primary-p2;
  @media (max-width: $media-mobile-mia-width) {
    height: 68px;
    background-color: $color-primary-p2;
    border-bottom: none;
    border-left: 2px solid $color-secondary-s3;
  }

  .inputField {
    border: 0;
    flex: 1;
    min-height: 40px;
    background-color: transparent;
    padding: 1px;
    font-family: $sans-serif-regular;
    color: $color-text-body;
    font-weight: $font-weight-regular;
    margin: 0 12px 0 12px;

    &::placeholder {
      opacity: .5;
      font-family: $sans-serif-regular;
      font-weight: $font-weight-regular;
    }
  }

  .inputField:focus, .inputField:active, .inputField:visited {
    outline: none;
  }

  button {
    border: 0;
    height: 40px;
    width: 40px;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    @media (max-width: $media-mobile-mia-width) {
     margin-right: 14px;
    }

    .icon {
      height: 20px;
      width: 20px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: $media-mobile-mia-width) {
        height: unset;
        width: unset;
      }

      span {
       @media (max-width: $media-mobile-mia-width) {
         font-size: 33px!important;
       }
     }
    }
  }
}

.textBox:focus-within {
  border: 2px solid $color-secondary-s3;
  margin: -2px;
  padding: 0;
  background-color: $color-white;
  @media (max-width: $media-mobile-mia-width) {
    background-color: $color-primary-p2;
    border: none;
    border-left: 2px solid $color-secondary-s3;
  }
}


// for chromium browsers
.scrollable::-webkit-scrollbar {
  width: 6px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: $color-primary-p3;
  outline: 0;
}
